import { connect } from 'react-redux';
import Header from '../header/header.js'
import Terms from '../overlays/terms.js';
import Unauthorized from '../overlays/unauthorized';
import UsersPage from '../users/usersPage';
import Footer from '../footer/footer';

function SecuredContent(props) {

    if (!props.authenticated) return null;

    if (!props.authorized) {
        return (
            <>
                <Unauthorized />
                {props.showTerms && <Terms closable={true} />}
            </>
        );
    }
    // TODO: Move to a different component if there is more than one pages
    return (
        <>
            <div className='section-top'>
                <Header />
                <UsersPage />
            </div>
            <Footer />
            {props.showTerms && <Terms closable={true} />}
        </>
        );

}

function mapStateToProps(state) {
  return {
    authenticated: state.keycloak.keycloak_authenticated,
    authorized: state.keycloak.authorized,
    showTerms: state.keycloak.show_terms,
  };
}


export default connect(mapStateToProps, null)(SecuredContent);