
import { FILTERS } from '../../constants/actions';


// Set search value for user filtering
export const setSearchValue = (value) => {
    if (value) value = value.trim();
    return {type: FILTERS.SET_SEARCH_VALUE, value}
}

// Reset Search value
export const resetSearchValue = ()  => (
    {type: FILTERS.RESET_SEARCH}
)

// order ASC or DESC
export const setUserOrder = (value) => (
    {type: FILTERS.SET_USER_ORDER, value}
)
// order by lastname or firstname
export const setUserOrderBy = (value) => (
    {type: FILTERS.SET_USER_ORDER_BY, value}
)
export const setGroupFilters = (groups) => (dispatch) => {
    if (!groups || !groups.length) return;
    dispatch ({type: FILTERS.SET_GROUPS, value: groups})
}


export const setGroupAcive = (id, active) => (
    {type: FILTERS.SET_GROUP_STATUS, id, active}
)

export const setUserFocus = (id) =>(
    {type: FILTERS.SET_USER_SELECTED, id}
)

export const toggleUserFocus = (id) =>(
    {type: FILTERS.TOGGLE_USER, id}
)