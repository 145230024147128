import { useSelector, useDispatch } from 'react-redux';
import {ReactComponent as ArrowIcon} from '../../icons/arrow.svg';
import {USERS_HEADER_FIRSTNAME, USERS_HEADER_LASTNAME, USERS_HEADER_GROUPS} from '../../constants/text'
import {setUserOrder, setUserOrderBy} from '../../redux/actions/filters';

const UsersPage = () => {
  const [orderBy, orderAsc] = useSelector((state) => [
    state.filters.orderBy,
    state.filters.orderAsc
  ]);
  const dispatch = useDispatch();

  const toggleOrder = (variable) => {
    if (orderBy === variable) {
      dispatch(setUserOrder(!orderAsc));
    }
    else {
      dispatch(setUserOrderBy(variable));
    }
  }

  return (
    <>
      <div className="users-header list-item">
        <div className="user-data-wrapper">
          <div className={`user-data cell ${orderBy === 'firstname' && 'selected'}`} onClick={()=>toggleOrder('firstname')}>
            <ArrowIcon className={`arrow ${(orderBy === 'lastname' || orderAsc) && 'upside-down'}`} />
            {USERS_HEADER_FIRSTNAME}
          </div>
          <div className={`user-data cell ${orderBy === 'lastname' && 'selected'}`} onClick={()=>toggleOrder('lastname')}>
            {USERS_HEADER_LASTNAME}
            <ArrowIcon className={`arrow ${(orderBy === 'firstname' || orderAsc) && 'upside-down'}`} />
          </div>
        </div>
        <div className="user-profiles cell">{USERS_HEADER_GROUPS}</div>
      </div>
    </>
  );
};
export default UsersPage;
