import { KEYCLOAK } from "../../constants/actions";

const initialState = {
    authorized: false,
    unauthorized_reason: '',
    keycloak_authenticated: false,
    keycloak_instance: undefined,
    keycloak_error: undefined,
    show_terms: false,
    idTokenParsed: null,
  };


const  keycloackReducer = (state = initialState, {type, value, reason, authorized}) => {
    switch (type) {
        case KEYCLOAK.SET_KEYCLOAK: {
            return {
                ...state,
                keycloak_instance: value,
            };
        }
        case KEYCLOAK.SET_AUTHENTICATION_ERROR: {
            return {
                ...state,
                keycloak_error: value
            };
        }
        case KEYCLOAK.SET_AUTHENTICATED: {
            return {
                ...state,
                keycloak_authenticated: value,
                authorized: authorized,
                unauthorized_reason: reason ? reason : undefined
          
            };
        }
        case KEYCLOAK.ACCEPT_TERMS: {
            let new_instance = state.keycloak_instance;
            new_instance.idTokenParsed.usage_terms_accepted_version = value;
            return {
                ...state,
                keycloak_instance: new_instance,
            };
        }
        case KEYCLOAK.TOGGLE_TERMS: {
            return {
                ...state,
                show_terms: !state.show_terms,
            };
        }
        default:
            return state;

    }
}

export default keycloackReducer;