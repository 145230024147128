import { FILTERS } from '../../constants/actions';

const initialState = {
  search: '',
  orderAsc: true,
  orderBy: 'lastname',
  groups: [],
  userselected: '',
};

// eslint-disable-next-line default-param-last
const filtersReducer = (state = initialState, { type, value, id, active }) => {
  switch (type) {
    case FILTERS.SET_SEARCH_VALUE: {
      return {
        ...state,
        search: value,
      };
    }
    case FILTERS.SET_USER_ORDER: {
      return {
        ...state,
        orderAsc: value,
      }
    }
    case FILTERS.SET_USER_ORDER_BY: {
      return {
        ...state,
        orderBy: value,
        orderAsc: true,
      }
    }
    case FILTERS.RESET_SEARCH: {
      return {
        ...state,
        search: '',
        userselected: '',
      };
    }
    case FILTERS.SET_USER_SELECTED: {
      return {
        ...state,
        userselected: id,
      }
    }
    case FILTERS.TOGGLE_USER: {
      return {
        ...state,
        userselected: (state.userselected === id ? '' : id),
      }
    }
    case FILTERS.SET_GROUPS: {
      return {
        ...state,
        groups: value.map(item => Object.assign(item, {active: true})),
      };
    }
    case FILTERS.SET_GROUP_STATUS: {
      const newGroups = state.groups.map(item => { 
          if (item.id === id) {
            item.active = active;
            return item;
          }
        return item;
      })

      return {
        ...state,
        groups: newGroups,
      }
    }
    default:
      return state;
  }
};

export default filtersReducer;
