import { GROUPS } from '../../constants/actions';
import {LINK_GET_GROUPS} from '../../constants/setup';
import { setGroupFilters } from './filters';
import { orderGroups } from '../../components/helpers/filters';

// Fetch Users data
export const getGroups = (token) => (dispatch)=> {
    if (!token || !token.length) return;
    const url = `${process.env.REACT_APP_API_ENDPOINT}${LINK_GET_GROUPS}`;
    var opts = { method: 'GET', 
    headers: {  
      'Content-Type': 'application/json',
         "Authorization": "Bearer " + token, 
         "Accept": "*/*",
        }
    }; 
    fetch(url, opts)
    .then(response=>response.json())
    .then(response => { 
        const groups = orderGroups(response) || [];
        dispatch(setGroups(groups))
        dispatch(setGroupFilters(groups))
    }).catch((err)=> {
        dispatch(setError());
        console.log(err)
    })
};

// Set users retrieved
export const setGroups = (groups) => ({
    type: GROUPS.SET_GROUPS, groups
})

// Error on feching data
export const setError = () => ({
    type: GROUPS.SET_ERROR
})

