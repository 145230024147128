import { useSelector } from 'react-redux';
import EmptyResults from './emptyResults';
import Loader from './loader';
import UserItem from './userItem';
import UserListHeader from './userListHeader';
import {filterResults, sortResult, filterGroups} from '../helpers/filters';
import {TEXT_NO_RESULTS} from '../../constants/text'

const Users = () => {
    const [loaded, search, orderBy, orderAsc, groups, filteredGroups] = useSelector((state) => [
        state.users.loaded,
        state.filters.search,
        state.filters.orderBy,
        state.filters.orderAsc,
        state.groups.groups,
        state.filters.groups
      ]);

    let users = useSelector((state) => state.users.users);
    if (!search || search.length < 3) {
      return (<div className='users'>
      <div className='users-list'>Please enter a name or an email address <span>(minimum: 3 caracters)</span></div></div>);
    }
    if (!users || !users.length) {
        // There is no users for the organization
        if (loaded) return <EmptyResults />;
        // The request is in process
        return <Loader />;
    }
  
    /*if (search && search.length) {
      users = filterResults(users, search);
    }*/

    users = filterGroups(users, filteredGroups);
  
    users = sortResult(users, orderBy, orderAsc);


    if (!users || !users.length) {
      return <EmptyResults text = {TEXT_NO_RESULTS} noresults = {true}/>;
    }

  return (
    <>        
        <div className='users'>
          <div className='users-list'>
            <UserListHeader />
            <div className='users-list-items'>
              {users.map((item,index) => 
                <UserItem key={`useritem_${index}`} item={item} orderBy={orderBy} groups={groups}/> 
              )}
            </div>
          </div>
       </div>
    </>
  );
};



export default Users;
