import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {ReactComponent as FilterIcon}  from '../../icons/filter.svg';
import {ReactComponent as ArrowIcon} from '../../icons/arrow.svg';
import {setGroupAcive} from '../../redux/actions/filters';
import {TEXT_FILTERS} from '../../constants/text';
import {ReactComponent as CheckBoxIcon} from '../../icons/check.svg';

const Filters = () => {
    const dispatch = useDispatch();
    const [filterHided, setFilterHided] = useState(true);
    const [groups] = useSelector((state) => [
      state.filters.groups
    ]);

    const setActive = (id, active, index) => {
      dispatch(setGroupAcive(id, !active));
    }
  return (
    <>        
        <div className='filters'>
          <div className='filters-wrapper'>
              <div className={`filters-cta ${!filterHided && 'selected' }`} onClick={()=>setFilterHided(!filterHided)}>
                  <div className='fiters-cta-wrapper'>
                    <FilterIcon className='filter-icon'/>
                    <span className='filters-text'>{TEXT_FILTERS}</span>
                    <ArrowIcon className={`arrow ${filterHided && 'upside-down' }`} />
                  </div>
                </div>
                
              <div className={`filters-container filters-container ${ filterHided && 'hide'}`}>
                <div className='filters-block'>
                  <div className='show-groups'>
                    {groups.map((item,index) => {
                      return <div className="group-row" key={`filters_${index}`} onClick={()=>setActive(item.id, item.active, index)} >
                        <span className={`checkbox-component ${item.active && 'selected'}`}> <CheckBoxIcon /></span>
                        {item.name}
                        </div>
                    })}
                    
                  </div >
                </div>
              </div>
          </div>
       </div>
    </>
  );
};



export default Filters;

