import React from 'react';
import { connect } from 'react-redux';
import './overlays.css'
import {ReactComponent as FeedbackIcon} from '../../icons/feedback.svg'
import * as typeformEmbed from '@typeform/embed';
import Header from '../header/header.js'
import { UNAUTHORIZED_TITLE_USE_REASON, UNAUTHORIZED_TITLE, UNAUTHORIZED_DEFAULT_MESSAGE, UNAUTHORIZED_REASON_SAFARI, UNAUTHORIZED_REASON_CODOPS} from '../../constants/setup';
import { TEXT_CONTACT_US } from '../../constants/text';

function Unauthorized(props) {
    const userInfo = props.keycloak && props.keycloak.idTokenParsed ? props.keycloak.idTokenParsed : null;
    const title = UNAUTHORIZED_TITLE || "Access Denied";
    let message = UNAUTHORIZED_DEFAULT_MESSAGE || "Something Went Wrong";
    if (UNAUTHORIZED_TITLE_USE_REASON) {
      if (props.reason === "Safari") {
          message = UNAUTHORIZED_REASON_SAFARI;
      }
      else if (props.reason === "CodOps") {
          message = UNAUTHORIZED_REASON_CODOPS;
      }
    }
  return (
    <div className="terms-background">
        <Header extraClasses='topPosition'/>
        <div className="unauthorized">
            <h1>{title}<span>{message}</span></h1>
            <button onClick={(e) => props.toggleFeedback(userInfo.email, userInfo.ebu_sso_code_ops, userInfo.sub)}><FeedbackIcon /> { TEXT_CONTACT_US }</button>
        </div>
    </div>
  );
}

function mapStateToProps(state) {
    return {
      authenticated: state.keycloak_authenticated,
      keycloak: state.keycloak_instance,
      reason: state.unauthorized_reason
    };
}


function mapDispatchToProps(dispatch) {
    return {
        toggleFeedback: (email, codops, userID) => {
            let typeformURL = "https://y705a4a2nbv.typeform.com/to/o1P2FKLT" 
                              + "#useragent=" + encodeURI(window.navigator.userAgent)
                              + "&email=" + email 
                              + "&codops=" + codops
                              + "&user_id=" + userID;
      
            const reference = typeformEmbed.makePopup(
              typeformURL,
              {
                mode: 'popup',
                hideHeaders: true,
                hideFooters: true,
                onSubmit: function () {
                  console.log('Typeform successfully submitted')
                }
              }
            )
            
            reference.open()
          },  
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Unauthorized);