import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom'
import { useParams } from "react-router-dom";

import AuthManager from './components/helpers/AuthManager';
import './index.css';
import './styles/main.scss';
import SecuredContent from './components/helpers/SecuredContent';

import {store} from './redux/store/store'


function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const parentLocation = (window.parent !== null) ? window.top.location : null;

ReactDOM.render(
  
  <React.StrictMode>
      <Router>
        <Route path='/' >
            <App />
        </Route>
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);


function App() {
  let query = useQuery();
  let params = useParams();
  if (parentLocation) {params["parent"] = parentLocation; }
 
  return (
    <Provider store={store}>  
        <AuthManager/> 
        <SecuredContent params={params} query={query}/>
    </Provider>
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
