import { KEYCLOAK } from '../../constants/actions';

export const setAuthenticated = (value = false, authorized = false) => {  
  return {
    type: KEYCLOAK.SET_AUTHENTICATED,
    value: value,
    authorized: authorized
  }
};

// logout the user from Keycloak
export const logoutUser = (keycloak) => (dispatch) => {
  if (!keycloak) return null;
  keycloak.logout().then(() => {
    dispatch(setAuthenticated(false, false));
  });
}

// Toggle Service terms
export const toggleTerms = () => ({ type: KEYCLOAK.TOGGLE_TERMS });
