import React from 'react';
import { connect } from 'react-redux';
import {KEYCLOAK} from '../../constants/actions';
import './overlays.css';


const baseURL = process.env.REACT_APP_API_ENDPOINT;
const terms_version = process.env.REACT_APP_TERMS_VERSION;

function Terms(props) {
    let classes = "terms-background";
    if (props.closable) {classes = classes + " closable";}
  return (
    <div className={classes} onClick={(e) => {if (props.closable) {props.toggleTerms()}}}>
        <div className="terms" onClick={(e) => e.stopPropagation()}>
            <h1>Terms of Use<span className="version">Version 2 - 22/01/2021</span></h1>
            { props.closable && <button className="close" onClick={(e) => {props.toggleTerms()}}>+</button>}
            <div className="text-wrapper">
            <p><b>By registering, you accept the terms and conditions set out below for the News Pilot monitoring tool and that your organization is legally bound by them. In case of doubt, please check with your organization that you have the required powers to assume this responsibility (you may be asked for providing a written confirmation thereof).</b></p>
                <h2>1) Scope of the News Pilot</h2>
                <p>The News Pilot monitoring tool (being the key element of the News Pilot Phase 1 and developed during May – December 2020) is a real-time monitoring tool operated by the European Broadcasting Union ("EBU") for all public service news content produced in EBU Members’ newsrooms. Participating Members are asked to integrate their online news stories (current affairs and/or in-depth news) in the form of a text article or in a multimedia format, i.e. a text article with video and/or audio and/or photos, for monitoring, translation and recommendation purposes.</p>
                <h2>2) Eligibility to participate</h2>
                <p><b>2.1</b> For the duration of the News Pilot phases which are funded by the EBU News Committee from the Earmarked Reserve funds during the period of 1 January - 31 December 2021, the monitoring tool will be provided free of charge to all Members participating in the Eurovision News Exchange and to those Members which do not participate in that Exchange (e.g. radio-only Members) but wish to integrate their content into the monitoring tool.</p>
                <p><b>2.2</b> Any participating Member shall express its commitment to the Core Values of Public Service Media <sup>1</sup> through the exchange of news content gathered and used according to the recommended guidelines laid out in the EBU’s document "The Values Translated: Editorial Guidelines". <sup>2</sup></p>
                <h2>3) Content monitoring</h2>
                <p><b>3.1</b> Each news story offered to the News Pilot must be a comprehensive piece, although it is possible that the core part is the media element while the accompanying text and title are short. The EBU monitoring tool provides all participating Members quick access to online multimedia content from other Members, and enables the translation of text, audio and video in European languages; the tool provides also various search options and recommendations. All content so offered is automatically translated into English. Translation into other languages is made on request.</p>
                <p><b>3.2</b> In the News Pilot Monitoring Tool, the participating Members agree to share their content in the News Pilot for monitoring purposes only. The monitoring tool is EBU-internal only, i.e. strictly accessible only to the participating Members’ journalists for their information but not to the general public. The monitoring purpose is facilitated by the making of translations and recommendations as described under paragraph <b>3.1</b> above, and any Member offering its content shall have cleared, where relevant, the necessary rights for such purposes.</p>
                <p><b>3.3</b> Participating Members acknowledge that the content exchanged via the News Pilot (whether or not translated or otherwise edited) cannot be republished by them in their online services. If a participating Member is interested in republishing the content of another Member, the Members concerned shall clarify bilaterally the rights clearance and other conditions for republishing on a case by case basis.</p>
                <h2>4) Governance and conflict resolution</h2>
                <p>Any issues arising from the lack of compliance with these terms and conditions should be notified to the News Pilot Steering Committee for a first scrutiny of the matter. If, according to the nature of the breach, a sanction is deemed appropriate by the Steering Committee, for example in the form of a temporary suspension or a full exclusion from the monitoring tool, the Steering Committee will present a recommendation for such sanction to the News Committee for its decision. If need be, the latter can refer the matter to the News Assembly. In exceptional cases, the matter can be referred to the EBU Executive Board.</p>
                <hr/>
                <p className="small"><sup>1</sup> See latest version posted on the <b><a href="https://www.ebu.ch/about/public-service-media" rel="noopener noreferrer" target="_blank">EBU website</a></b>, may be amended by EBU<br/>
                <sup>2</sup> See latest version posted on the <b><a href="https://www.ebu.ch/files/live/sites/ebu/files/Publications/EBU-Editorial-Guidelines_EN.pdf" rel="noopener noreferrer" target="_blank">EBU website</a></b>, may be amended by EBU</p>
            </div>
            {!props.closable &&
                <button onClick={(e) => {props.acceptTerms(terms_version, props.token);}}>I accept</button>
            }
        </div>
    </div>
  );
}

function mapStateToProps(state) {
    return {
      authenticated: state.keycloak_authenticated,
      termsVersion: (state.keycloak_instance && state.keycloak_instance.idTokenParsed) ? state.keycloak_instance.idTokenParsed.usage_terms_accepted_version : 0,
      token: (state.keycloak_instance && state.keycloak_instance.token) ?  state.keycloak_instance.token : undefined
    };
}


function mapDispatchToProps(dispatch) {
    return {
        acceptTerms: (version, token) => {
            sendAcceptedTerms(dispatch, version, token);
        },
        toggleTerms: () => {
            dispatch({ type: KEYCLOAK.TOGGLE_TERMS });
        },
    }
}

async function sendAcceptedTerms(dispatch, version, token) {
    var url = baseURL + "/api/terms/?version=" + version;
    var opts = { method: 'PUT', headers: { "Authorization": "Bearer " + token, "Accept": "*/*" } };
    fetch(url, opts)
    .then(() => { 
      const action = { type: KEYCLOAK.ACCEPT_TERMS, value: version};
      dispatch(action);
    })
    .catch((error) => {
        const action = { type: KEYCLOAK.ACCEPT_TERMS, value: version};
      dispatch(action);
      });
  }

export default connect(mapStateToProps, mapDispatchToProps)(Terms);