import { GROUPS } from '../../constants/actions';

const initialState = {
  groups: [],
  loaded: false,
  error: false,
};

// eslint-disable-next-line default-param-last
const groups = (state = initialState, { type, groups }) => {
  switch (type) {
    case GROUPS.SET_GROUPS: {
        return {
            ...state,
            groups,
            loaded: true,
            error: false,
        };
    }
    case GROUPS.SET_ERROR: {
        return {
            ...state,
            error: true,
        };
    }
    default:
      return state;
  }
};

export default groups;
