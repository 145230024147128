import { USERS } from '../../constants/actions';
import { LINK_GET_USERS, LINK_GET_USER, LINK_SET_USER } from '../../constants/setup';

// Fetch Users data
export const getUsers = (token, search) => (dispatch)=> {
    if (!search || search.length < 3) return;
    if (!token || !token.length) return;
    const url = `${process.env.REACT_APP_API_ENDPOINT}${LINK_GET_USERS}`;
    var opts = { method: 'GET', 
    headers: {  
      'Content-Type': 'application/json',
         "Authorization": "Bearer " + token, 
         "Accept": "*/*",
        }
    }; 
    fetch(url + '?q=' + search, opts)
    .then(response=>response.json())
    .then(response => { 
        const users = response || [];
        dispatch(setUsers(users))
        // dispatch users
    }).catch((err)=> {
        dispatch(setError());
        console.log(err)
    })
};

// Fetch Users data
export const getUserInfo = (token, id) => (dispatch)=> {
    if (!token || !token.length) return;
    const url = `${process.env.REACT_APP_API_ENDPOINT}${LINK_GET_USER.replace('##ID##', id)}`;
    var opts = { method: 'GET', 
    headers: {  
      'Content-Type': 'application/json',
         "Authorization": "Bearer " + token, 
         "Accept": "*/*",
        }
    }; 
    fetch(url, opts)
    .then(response=>response.json())
    .then(response => { 
        dispatch(setUserInfo(response))
    }).catch((err)=> {
        console.log(err)
    })
};

// Set users retrieved
export const setUsers = (users) => ({
    type: USERS.SET_USERS, users
})

// Error on feching data
export const setError = () => ({
    type: USERS.SET_ERROR
})

export const setUserInfo = (user) =>({
    type: USERS.SET_USER_INFO, user
})


export const updateUser = (user, users) => (dispatch) => {
    if (!users || !users.length) return;
    users.map(item => {
        if (item.id === user.id) {
            return user
        }
        return item;
    });
    return ({type: USERS.SET_USERS, users })
}


export const updateUserGroup = (token, user) => (dispatch) => {
    if (!token || !token.length || !user || Object.keys(user).length === 0 ) return;

    const url = `${process.env.REACT_APP_API_ENDPOINT}${LINK_SET_USER.replace('##ID##', user.id)}`;
    var opts = { 
        method: 'PUT', 
        headers: {  
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token, 
            "Accept": "*/*",
        },
        body: JSON.stringify(user)
    }; 
    fetch(url, opts)
    .then(response=>response.json())
    .then(response => { 
        if (!response || !Object.keys(response).length) {
            // Error response
            console.log("Empty response")
        } 
        // There is no need to updated the redux store at this point
    }).catch((err)=> {
        console.log(err)
    })
}


