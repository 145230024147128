import React, {useState, useEffect} from 'react';
import {ReactComponent as IconSearc}  from '../../icons/search.svg'
import {setSearchValue, resetSearchValue} from '../../redux/actions/filters';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../redux/actions/users';
const Search = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const keycloakToken = useSelector(state=>state.keycloak.keycloak_instance.token);

  useEffect(() => {
    dispatch(resetSearchValue());
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  },[]);

  const sendSearchValue = () => {
    dispatch(setSearchValue(searchText));
    dispatch(getUsers(keycloakToken, searchText))
  }

  return (
    <>        
        <div className='search'>
          <input className='search-input' onChange={(e)=> {setSearchText(e.target.value); dispatch(setSearchValue(e.target.value));dispatch(getUsers(keycloakToken, e.target.value))}}/>
          <button className='search-button' onClick={() => sendSearchValue()}>
            <IconSearc className='search-icon' />
          </button>
       </div>
    </>
  );
};



export default Search;
