import  {useEffect} from 'react';
import Keycloak from "keycloak-js";
import { connect } from 'react-redux';
import {KEYCLOAK} from '../../constants/actions';

const keycloak_URL = process.env.REACT_APP_KEYCLOAK_URL;
const keycloak_clientID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
const keycloak_Realm = process.env.REACT_APP_KEYCLOAK_REALM;
const authorized_codops = process.env.REACT_APP_AUTHORIZED_CODOPS;

function AuthManager(props) {
    const codops = authorized_codops.split(',');

    useEffect(() => {
        if (keycloak_clientID && keycloak_Realm && keycloak_URL) {
            const keycloak = Keycloak({
                clientId: keycloak_clientID,
                realm: keycloak_Realm,
                url: keycloak_URL
            });
            // Trigger Keycloak authentication
            keycloak.init({onLoad: "login-required"})
                .then(authenticated => {
                    props.setKeycloakInstance(keycloak);
                    props.setAuthenticated(authenticated, codops.indexOf(keycloak.idTokenParsed.ebu_sso_code_ops) > -1);
                })
                .catch(reason => {
                    props.setAuthenticationError(reason);
                    console.log("SSO authentication failure ", reason);
                });
            // Add event method on token expired to refresh token
            keycloak.onTokenExpired = () => {
                keycloak.updateToken(30)
                    .then(() => console.log("SSO refresh authentication with success"))
                    .catch(reason => {
                        console.log("SSO refresh authentication failure", reason);
                    });
            }
        }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);


    return ('');
}

function mapStateToProps(state) {
    return {
      
    };
  }
  
  
function mapDispatchToProps(dispatch) {
    return {
        setKeycloakInstance: (keycloak) => {
            dispatch({ type: KEYCLOAK.SET_KEYCLOAK, value: keycloak });
        },
        setAuthenticationError: (error) => {
            dispatch({ type: KEYCLOAK.SET_AUTHENTICATION_ERROR, value: error });
        },
        setAuthenticated: (authenticated, authorized) => {
            dispatch({ type: KEYCLOAK.SET_AUTHENTICATED, value: authenticated, authorized: authorized, reason: "CodOps" });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthManager);