
const removeAccents = (str) => {
    if (!str) return '';
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

// Filter array users by text
export const filterResults = (users, searchText) => {
    if (!searchText || !searchText.length) return users;
    const newSearchText = removeAccents(searchText)?.toLowerCase()
    return users.filter((item) => {
        const result1 =
            item.first_name &&
            removeAccents(item.first_name).toLowerCase().includes(newSearchText);
        const result2 =
            item.last_name &&
            item.last_name.toLowerCase().includes(searchText.toLowerCase());
        const result3 = item.email && item.email.toLowerCase().includes(newSearchText);
        return result1 || result2 || result3;
    });
};

// Show only the users with groups active
export const filterGroups = (users, groups) => {
    if (!groups.find((item) => !item.active)) return users;
    return users.filter((userItem) => {
        return userItem.groups.find((item) => {
            return groups.find((groupItem) => {
                return groupItem.id === item.id && groupItem.active;
            });
        });
    });
};

export const sortResult = (users, orderBy, orderAsc) => {
    // orderBy: use the "lastname" | "firstname" to sort the data
    // orderAsc: sort in alphabetical order
    if (!users || !users.length) return [];
    return users.sort((a, b) => {
        if (orderBy === 'lastname') {
            if (orderAsc) {
                return `${a.last_name} ${a.first_name}`.localeCompare(
                    `${b.last_name} ${b.first_name}`
                );
            }
            return `${b.last_name} ${b.first_name}`.localeCompare(
                `${a.last_name} ${a.first_name}`
            );
        }
        if (orderAsc) {
            return `${a.first_name} ${a.last_name}`.localeCompare(
                `${b.first_name} ${b.last_name}`
            );
        }
        return `${b.first_name} ${b.last_name}`.localeCompare(
            `${a.first_name} ${a.last_name}`
        );
    });
};

export const showGroups = (itemNews, groups) => {
    const items = itemNews.groups;
    if (!items || !items.length || !groups || !groups.length) return null;
    const itemsWithGroups = items.map((item) => {
        const group = groups.find((groupItem) => groupItem.id === item.id);
        if (!group) {
            // We can't find the group assigned to the user
            return item;
        }
        return Object.assign(item, {
            group_name: group.name || '',
            group_description: group.description || '',
        });
    });
    return itemsWithGroups.map((item, index) => (
        <div
            tooltip={item.group_description || ''}
            className='group_name'
            key={`grouop_item_${itemNews.id}_${index}`}
        >
            {item.group_name}
        </div>
    ));
};

export const setUserGroupActive = (user, idgroup, status) => {
    let found = false;
    user.groups = user.groups.filter((item) => {
        if (item.id === idgroup) {
            found = true;
            if (!status) {
                return false;
            }
        }
        return true;
    });
    if (!found && status) {
        if (!user.groups) user.groups = [];
        user.groups.push({ id: idgroup, name: '', description: '' });
    }
    return user;
};

export const getGroupState = (items, groups) => {
    if (!groups || !groups.length) return [];
    const newGroups = groups.map((groupItem) => {
        const itemSelected =
            items && items.length
                ? items.find((i) => i.id === groupItem.id)
                : false;
        const isActive =
            itemSelected && Object.keys(itemSelected).length ? true : false;
        return isActive;
    });
    return newGroups;
};

export const removeGroupsDueToRoles = (user, groups, idgroup) => {
    // If you choose a low level group, the groups above will be automatically deselected
    // Status in this functions needs to be 'false'
    let roles = [];
    let newGroups = [];
    let groupsNumber = 0;

    const groupSelected = groups.find((item) => item.id === idgroup);
    if (!groupSelected || !groupSelected.roles || !groupSelected.roles.length)
        return;
    groupsNumber = groupSelected.roles.length;
    groupSelected.roles.map((i) => {
        roles.push(i.id);
        return true;
    });

    user.groups.map((item) => {
        const groupSelected = groups.find(
            (itemGroup) => item.id === itemGroup.id
        );
        if (!groupSelected) return false;

        const isIncluded = groupSelected.roles.find((i) =>
            roles.includes(i.id)
        );
        if (!isIncluded || groupSelected.roles.length < groupsNumber)
            newGroups.push(item);
        return true;
    });

    user.groups = newGroups;
    return user;
};

export const addGroupsDueToRoles = (user, groups) => {
    // If you choose a high level group, the groups underneath will be automatically selected
    // Status in this functions needs to be 'true'
    let roles = [];
    let userGroups = [];

    user.groups.map((item) => {
        const group = groups.find((groupItem) => groupItem.id === item.id);
        if (group) {
            group.roles.map((i) => {
                const x = roles.find((role) => {
                    return i.id === role;
                });
                if (!x) {
                    roles.push(i.id);
                }
                return true;
            });
        }
        return false;
    });

    user.groups.map((item) => userGroups.push(item.id));

    groups.map((groupItem) => {
        if (userGroups.includes(groupItem.id)) return true;
        let allIncluded = true;
        groupItem.roles.map((roleItem) => {
            if (!roles.includes(roleItem.id)) {
                allIncluded = false;
            }
            return true;
        });
        if (allIncluded) {
            // && status
            user.groups.push({ id: groupItem.id, name: '', description: '' });
        }
        return true;
    });
    return user;
};

export const orderGroups = (groups) => {
    if (!groups || !groups.length) return [];

    const groupsSorted = groups.sort((a, b) => {
        const aID = a.name.toString();
        const bID = b.name.toString();

        if (aID.substring(0, 3) === bID.substring(0, 3)) {
            return b.roles.length - a.roles.length;
        }
        if (aID > bID) return 1;
        if (aID < bID) return -1;
        return 0;
    });
    return groupsSorted;
};
