import { USERS } from '../../constants/actions';

const initialState = {
  users: [],
  loaded: false,
  error: false,
  userInfo: {},
};

// eslint-disable-next-line default-param-last
const users = (state = initialState, { type, users, user, user_id}) => {
  switch (type) {
    case USERS.SET_USERS: {
      // Get first_name && last_name
      const newUsers = users.map((item) => {
        if (item.name && item.name.length) {
          const arr = item.name.split(/ (.*)/);
          return Object.assign(item, {first_name: arr[0] || item.first_name || '', last_name: arr[1] || item.last_name || ''})
        }
        if (!item.first_name) Object.assign(item, {first_name: ''})
        if (!item.last_name) Object.assign(item, {last_name: ''})
        return item;
      })
      return {
        ...state,
        users: newUsers,
        loaded: true,
        error: false,
      };
    }
    case USERS.SET_USER_INFO: {
      return {
        ...state,
        userInfo: user,
      }
    }
    case USERS.RESET_USERS: {
      return {
        ...state,
        users: [],
        loaded: false,
        error: false,
      };
    }
    case USERS.SET_ERROR: {
      return {
          ...state,
          error: true,
      };
  }
    default:
      return state;
  }
};

export default users;
