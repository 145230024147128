import React, { useState, useEffect } from 'react';
import {
    showGroups,
    setUserGroupActive,
    getGroupState,
} from '../helpers/filters';
import { useSelector, useDispatch } from 'react-redux';
import { updateUser, updateUserGroup } from '../../redux/actions/users';
import { setUserFocus, toggleUserFocus } from '../../redux/actions/filters';
import { ReactComponent as CheckBoxIcon } from '../../icons/check.svg';
import { TEXT_SAVE_CHANGES } from '../../constants/text';

/*
  User item structure
  {
    id
    first_name
    last_name
    email
    groups
    roles: []
    attributes
  }
*/

const UserItem = ({ item, orderBy, groups }) => {
    const [
        userselected,
        users,
        keycloakToken,
        userId,
    ] = useSelector((state) => [
        state.filters.userselected,
        state.users.users,
        state.keycloak.keycloak_instance.token,
        state.keycloak.keycloak_instance.idTokenParsed.sub,
    ]);
    const dispatch = useDispatch();

    const [groupsState, setGroupState] = useState(
        getGroupState(item.groups, groups)
    );
    const [activeButton, setButtonActive] = useState(false);

    useEffect(() => {
        setGroupState(getGroupState(item.groups, groups));
        setButtonActive(item.active);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [item]);

    if (!item) return null;

    const isVisible = userselected === item.id;

    const setFocus = () => {
        // TODO: For the moment we dont require more information from the user
        // if (keycloakToken) dispatch(getUserInfo(keycloakToken, item.id))
        dispatch(toggleUserFocus(item.id));
    };

    const setActive = (user, idgroup, status) => {
        if (user.id === userId) {
            // The customer can't modify their own groups
            return;
        }

        const newUserAux = setUserGroupActive(user, idgroup, status);

        // The next code asign or remove new roles automatically depending of the groups hiearchy
        // const newUser = status
        //    ? addGroupsDueToRoles(newUserAux, groups)
        //    : removeGroupsDueToRoles(newUserAux, groups, idgroup);
        // const newUserToUpdate = Object.assign(newUser, { active: true });
        const newUserToUpdate = Object.assign(newUserAux, { active: true });

        dispatch(updateUser(newUserToUpdate, users));
        // dispatch(updateUserGroup(keycloakToken, newUser));

        let arrayStates = [...groupsState];
        groups.map((i, pos) => {
            // const position = newUser.groups.find((item) => item.id === i.id);
            const position = newUserAux.groups.find((item) => item.id === i.id);

            if (position) arrayStates[pos] = true;
            else arrayStates[pos] = false;
            return true;
        });
        setGroupState(arrayStates);
        setButtonActive(true);
    };

    const saveUserData = (user) => {
        setButtonActive(false);
        dispatch(updateUser(Object.assign(user, { active: false }), users));
        dispatch(updateUserGroup(keycloakToken, user));
        dispatch(setUserFocus(''));
    };

    const showGroupsToEdit = (user, groups, groupsState) => {
        if (!groups || !groups.length) return null;
        let previousItemPreffix = '';

        // Split groups into sections depending on the name
        let groupsByName = [];
        let currentPosition = -1;
        groups.map((item, index) => {
            const currentPreffix = item.name.substring(0, 4);
            const addBorder = previousItemPreffix !== currentPreffix;
            if (addBorder) {
                currentPosition++;
                groupsByName[currentPosition] = [];
                previousItemPreffix = currentPreffix;
            }
            Object.assign(item, { arrayPos: index });
            groupsByName[currentPosition].push(item);
            return true;
        });

        return (
            <div className='group-edit-section'>
                {groupsByName.map((groupByNameItem, index) => {
                    return (
                        <div
                            className={`section section-${index % 2}`}
                            key={`section_${user.id}_${index}`}
                        >
                            {groupByNameItem.map((groupItem, index2) => {
                                const indexGeneric = groupItem.arrayPos;
                                return (
                                    <div
                                        className={`group_name ${
                                            groupsState[indexGeneric] &&
                                            'selected'
                                        }`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setActive(
                                                user,
                                                groupItem.id,
                                                !groupsState[indexGeneric]
                                            );
                                        }}
                                        tooltip={groupItem.description || ''}
                                        key={`group_selected_${user.id}_${index}_${index2}_${indexGeneric}`}
                                    >
                                        <span className='checkbox-component'>
                                            {' '}
                                            <CheckBoxIcon />
                                        </span>
                                        <span className='item-name'>
                                            {groupItem.name}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
                <div className='button-wrap'>
                    <button
                        className={`button-save ${
                            activeButton ? 'active' : 'inactive'
                        }`}
                        disabled={`${!activeButton ? 'disabled' : ''}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            saveUserData(user);
                        }}
                    >
                        {TEXT_SAVE_CHANGES}
                    </button>
                </div>
            </div>
        );
    };

    return (
        <>
            <div
                className={`user list-item ${isVisible && 'visible'} ${
                    !isVisible && activeButton && 'activebutton'
                }`}
                onClick={() => {
                    setFocus();
                }}
                tabIndex='-1'
            >
                <div className='user-data cell'>
                    <div className='name'>
                        <span>
                            {orderBy === 'lastname' ? (
                                item.first_name
                            ) : (
                                <b>{item.first_name} </b>
                            )}
                        </span>
                        <span>
                            {orderBy === 'lastname' ? (
                                <b> {item.last_name}</b>
                            ) : (
                                item.last_name
                            )}
                        </span>
                    </div>
                    {isVisible && <div className='email'>{item.email}</div>}
                </div>
                <div className='user-profiles cell'>
                    {!isVisible
                        ? showGroups(item, groups)
                        : showGroupsToEdit(item, groups, groupsState)}
                </div>
            </div>
        </>
    );
};

export default UserItem;
