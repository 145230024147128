import React from 'react';
import {ReactComponent as LogoEBU}  from '../../logos/ebu.svg'
import {ReactComponent as UserIcon} from '../../icons/default_user.svg'
import {ReactComponent as LogoutIcon} from '../../icons/logout.svg'
import { useSelector, useDispatch } from 'react-redux';
import {logoutUser} from '../../redux/actions/keycloak';

function Header({extraClasses}) {
  const dispatch = useDispatch();

  let keycloak = useSelector(state=>state.keycloak.keycloak_instance);
  if (!keycloak) return null;

  const userInfo = keycloak.idTokenParsed || {name: '', email: ''};
  const username = userInfo.name || userInfo.email || '';

  return (
    <header id='header' className={`${extraClasses && extraClasses.length > 0 ?  extraClasses : ''}`}>
      <div className='header-wrapper'>
        <LogoEBU />
        <div className="buttons">
          <div className="noselect user">
              <div className= 'user-info'>
              <UserIcon /> 
              <span>{username}</span>
              </div>
              <button onClick={() => dispatch(logoutUser(keycloak)) } ><LogoutIcon /></button>
          </div>
        </div>
      </div>
    </header>
  );
}


export default Header;