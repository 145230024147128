
import { combineReducers } from 'redux';

import usersReducer from './users';
import keycloakReduder from './keycloak';
import filterReducer from './filters';
import groupsReducer from './groups';


// We keep all the reducers at the same flat level
const rootReducer =  combineReducers({
    users: usersReducer,
    keycloak: keycloakReduder,
    filters: filterReducer,
    groups: groupsReducer,
  });

export default rootReducer;
