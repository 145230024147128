
const Footer = () => {

  return (
    <>
      <footer className='footer'>
        <div className='footer-wrapper'>
        </div>
      </footer>
    </>
  );
};



export default Footer;
