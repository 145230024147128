// Links actions
export const LINK_GET_USERS = '/api/users/';
export const LINK_GET_USER = '/api/users/##ID##';
export const LINK_SET_USER = '/api/users/##ID##';
export const LINK_GET_GROUPS = '/api/groups/';

// Unauthorized Error Messages
export const UNAUTHORIZED_TITLE_USE_REASON = true; // Show a message depending on the state.reason
export const UNAUTHORIZED_TITLE = 'Access Denied';
export const UNAUTHORIZED_DEFAULT_MESSAGE = 'You don’t currently have permission to access. Please contact your application administrator.';
// Unauthorized Error Messages due to state.reason
export const UNAUTHORIZED_REASON_SAFARI = "Please try using another browser to access the News Pilot monitoring tool. Contact us if you are still experiencing this issue with Chrome or Firefox.";
export const UNAUTHORIZED_REASON_CODOPS = "Your organisation does not have access to this application";