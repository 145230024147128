import { ReactComponent as LoaderIcon } from '../../icons/loader.svg';

const Loader = () => {
    
    return (
      <div className='loading'>       
        <div className='loading-wrapper'>   
            <LoaderIcon />
        </div>
      </div>
    );
  };
  
  
  
  export default Loader;
  