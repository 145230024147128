// Users Page
export const USERS_TITLE = 'Users';
export const TEXT_NO_USERS = 'There are no users assigned to this organization.'
export const USERS_HEADER_FIRSTNAME = 'First Name';
export const USERS_HEADER_LASTNAME = 'Last Name';
export const USERS_HEADER_PROFILES = 'Profiles';
export const USERS_HEADER_GROUPS = 'Roles';
export const TEXT_NO_RESULTS = 'No match'
export const TEXT_FILTERS = 'Filters';
export const TEXT_SAVE_CHANGES = 'Save changes';
export const TEXT_CONTACT_US = 'Contact us';