import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Search from './search';
import Users from './users';
import { getGroups } from '../../redux/actions/groups';

import Filters from './filters';

const UsersPage = () => {
  const dispatch = useDispatch();
  const keycloakToken = useSelector(state=>state.keycloak.keycloak_instance.token);
  
  useEffect(()=>{
    if (!keycloakToken || !keycloakToken.length) return;
    dispatch(getGroups(keycloakToken));
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [keycloakToken])
  
  return (
    <>
      <div className="content">
        <div className="content-wrapper">
          <Search />
          <Filters />
          <Users />
        </div>
      </div>
    </>
  );
};



export default UsersPage;
