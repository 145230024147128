// Keycloack
export const KEYCLOAK = {
    SET_KEYCLOAK: 'SET_KEYCLOAK',
    SET_AUTHENTICATION_ERROR: 'SET_AUTHENTICATION_ERROR',
    SET_AUTHENTICATED: 'SET_AUTHENTICATED',
    ACCEPT_TERMS: 'ACCEPT_TERMS',
    TOGGLE_TERMS: 'TOGGLE_TERMS',
}

// Filters results
export const FILTERS = {
    SET_SEARCH_VALUE: 'SET_SEARCH_VALUE',
    RESET_SEARCH: 'RESET_SEARCH',
    SET_USER_ORDER: 'SET_USER_ORDER', // true: `ASC order`, false: `DESC order`
    SET_USER_ORDER_BY: 'SET_USER_ORDER_BY', // 'lastname' or 'firstname'
    SET_GROUPS: 'SET_GROUP_FILTERS',
    SET_GROUP_STATUS: 'SET_GROUP_STATUS',
    SET_USER_SELECTED: 'SET_USER_SELECTED',
    TOGGLE_USER: 'TOGGLE_USER',
}

// Users
export const USERS = {
    GET_USERS: 'GET_USERS',
    SET_USERS: 'SET_USERS',
    RESET_USERS: 'SET_USERS',
    SET_ERROR: 'USERS_ERROR',
    SET_USER_INFO: 'SET_USER_INFO',
}

// Groups
export const GROUPS = {
    SET_GROUPS: 'GROUPS',
    SET_ERROR: 'GROUPS_ERROR',
}