import { TEXT_NO_USERS } from '../../constants/text';

const EmptyResults = ({text, noresults}) => {
    
    return (
      <div className={`emptyresults ${noresults && 'noresults'}`}>       
        <div className='emptyresults-text'>   
          {text || TEXT_NO_USERS}
        </div>
      </div>
    );
  };
  
  
  
  export default EmptyResults;
  